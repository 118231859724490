import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as sREAv2 } from "../abi/sReav2.json";
import { abi as fuseProxy } from "../abi/FuseProxy.json";
import { abi as wsREA } from "../abi/wsREA.json";

import { setAll } from "../helpers";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk, ICalcUserBondDetailsAsyncThunk } from "./interfaces";
import { FuseProxy, IERC20, SReav2, WsREA } from "src/typechain";

interface IUserBalances {
  balances: {
    rea: string;
    srea: string;
    fsrea: string;
    wsrea: string;
    wsreaAsSrea: string;
    pool: string;
  };
}

export const getBalances = createAsyncThunk(
  "account/getBalances",
  async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
    const reaContract = new ethers.Contract(addresses[networkID].REA_ADDRESS as string, ierc20Abi, provider) as IERC20;
    console.log("provider", provider);
    const reaBalance = await reaContract.balanceOf(address);
    const totalvaluedeposited = await reaContract.totalSupply();
    const sreaContract = new ethers.Contract(
      addresses[networkID].SREA_ADDRESS as string,
      ierc20Abi,
      provider,
    ) as IERC20;
    const sreaContracts = new ethers.Contract(addresses[networkID].SREA_ADDRESS as string, sREAv2, provider) as SReav2;

    const sreaBalance = await sreaContract.balanceOf(address);
    const circSupply = await sreaContracts.circulatingSupply();
    console.log("sreabalance", sreaBalance);
    const totalSupply = await sreaContracts.totalSupply();
    const wsreaContract = new ethers.Contract(addresses[networkID].WSREA_ADDRESS as string, wsREA, provider) as WsREA;
    // const wsreaBalance = await wsreaContract.balanceOf(address);
    const wsreaBalance = 0;

    // NOTE (appleseed): wsreaAsSrea is wsREA given as a quantity of sREA
    //const wsreaAsSrea = await wsreaContract.wREATosREA(wsreaBalance);
    const wsreaAsSrea = 0;
    const poolTokenContract = new ethers.Contract(
      addresses[networkID].PT_TOKEN_ADDRESS as string,
      ierc20Abi,
      provider,
    ) as IERC20;
    const poolBalance = 0;

    // let fsreaBalance = BigNumber.from(0);
    // let fsreaBalance = 0;

    // for (const fuseAddressKey of ["FUSE_6_SREA", "FUSE_18_SREA", "FUSE_36_SREA"]) {
    //   if (addresses[networkID][fuseAddressKey]) {
    //     const fsreaContract = new ethers.Contract(
    //       addresses[networkID][fuseAddressKey] as string,
    //       fuseProxy,
    //       provider.getSigner(),
    //     ) as FuseProxy;
    //     // fsreaContract.signer;
    //     const balanceOfUnderlying = await fsreaContract.callStatic.balanceOfUnderlying(address);
    //     fsreaBalance = balanceOfUnderlying.add(fsreaBalance);
    //   }
    // }
    let fsreaBalance = 0;

    return {
      balances: {
        rea: ethers.utils.formatUnits(reaBalance, "gwei"),
        srea: ethers.utils.formatUnits(sreaBalance, "gwei"),
        fsrea: ethers.utils.formatUnits(fsreaBalance, "gwei"),
        wsrea: ethers.utils.formatEther(wsreaBalance),
        wsreaAsSrea: ethers.utils.formatUnits(wsreaAsSrea, "gwei"),
        pool: ethers.utils.formatUnits(poolBalance, "gwei"),
        totalvaluedeposited: ethers.utils.formatUnits(totalvaluedeposited, "gwei"),
        circsupply: ethers.utils.formatUnits(circSupply, "gwei"),
        totalsupply: ethers.utils.formatUnits(totalSupply, "gwei"),
      },
    };
  },
);

interface IUserAccountDetails {
  staking: {
    reaStake: number;
    reaUnstake: number;
  };
  wrapping: {
    sreaWrap: number;
    wsreaUnwrap: number;
  };
}

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk, { dispatch }) => {
    const reaContract = new ethers.Contract(addresses[networkID].REA_ADDRESS as string, ierc20Abi, provider) as IERC20;
    const stakeAllowance = await reaContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);

    const sreaContract = new ethers.Contract(addresses[networkID].SREA_ADDRESS as string, sREAv2, provider) as SReav2;
    const unstakeAllowance = await sreaContract.allowance(address, addresses[networkID].STAKING_ADDRESS);
    const poolAllowance = await sreaContract.allowance(address, addresses[networkID].PT_PRIZE_POOL_ADDRESS);
    const wrapAllowance = await sreaContract.allowance(address, addresses[networkID].WSREA_ADDRESS);

    // const wsreaContract = new ethers.Contract(addresses[networkID].WSREA_ADDRESS as string, wsREA, provider) as WsREA;
    // const unwrapAllowance = await wsreaContract.allowance(address, addresses[networkID].WSREA_ADDRESS);
    const wsreaContract = 0;
    const unwrapAllowance = 0;
    await dispatch(getBalances({ address, networkID, provider }));

    return {
      staking: {
        reaStake: +stakeAllowance,
        reaUnstake: +unstakeAllowance,
      },
      wrapping: {
        reaWrap: +wrapAllowance,
        reaUnwrap: +unwrapAllowance,
      },
      pooling: {
        sreaPool: +poolAllowance,
      },
    };
  },
);

export interface IUserBondDetails {
  allowance: number;
  interestDue: number;
  bondMaturationBlock: number;
  pendingPayout: string; //Payout formatted in gwei.
}
export const calculateUserBondDetails = createAsyncThunk(
  "account/calculateUserBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: 0,
        balance: "0",
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }
    // dispatch(fetchBondInProgress());

    // Calculate bond details.
    const bondContract = bond.getContractForBond(networkID, provider);
    const reserveContract = bond.getContractForReserve(networkID, provider);

    let pendingPayout, bondMaturationBlock;

    const bondDetails = await bondContract.bondInfo(address);
    let interestDue: BigNumberish = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
    bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
    pendingPayout = await bondContract.pendingPayoutFor(address);

    let allowance,
      balance = BigNumber.from(0);
    allowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
    balance = await reserveContract.balanceOf(address);
    // formatEthers takes BigNumber => String
    const balanceVal = ethers.utils.formatEther(balance);
    // balanceVal should NOT be converted to a number. it loses decimal precision
    return {
      bond: bond.name,
      displayName: bond.displayName,
      bondIconSvg: bond.bondIconSvg,
      isLP: bond.isLP,
      allowance: Number(allowance.toString()),
      balance: balanceVal,
      interestDue,
      bondMaturationBlock,
      pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
    };
  },
);

interface IAccountSlice extends IUserAccountDetails, IUserBalances {
  bonds: { [key: string]: IUserBondDetails };
  balances: {
    rea: string;
    srea: string;
    dai: string;
    oldsrea: string;
    fsrea: string;
    wsrea: string;
    wsreaAsSrea: string;
    pool: string;
    totalvaluedeposited: string;
    circSupply: string;
    totalSupply: string;
  };
  loading: boolean;
  staking: {
    reaStake: number;
    reaUnstake: number;
  };
  pooling: {
    sreaPool: number;
  };
}

const initialState: IAccountSlice = {
  loading: false,
  bonds: {},
  balances: {
    rea: "",
    srea: "",
    dai: "",
    oldsrea: "",
    fsrea: "",
    wsrea: "",
    pool: "",
    wsreaAsSrea: "",
    totalvaluedeposited: "",
    circSupply: "",
    totalSupply: "",
  },
  staking: { reaStake: 0, reaUnstake: 0 },
  wrapping: { sreaWrap: 0, wsreaUnwrap: 0 },
  pooling: { sreaPool: 0 },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log("err", error);
      })
      .addCase(getBalances.pending, state => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(calculateUserBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
        if (!action.payload) return;
        const bond = action.payload.bond;
        state.bonds[bond] = action.payload;
        state.loading = false;
      });
    // .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
    //   state.loading = false;
    //   console.log(error);
    // });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
