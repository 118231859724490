import { useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { useAppSelector } from "src/hooks";

import { Typography, Box } from "@material-ui/core";
import { trim, formatCurrency } from "../../../../helpers";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip.jsx";
import "../../treasury-dashboard.scss";
export const Metric = props => <Box className={`metric ${props.className}`}>{props.children}</Box>;

Metric.Value = props => <Typography variant="h5">{props.children || <Skeleton type="text" />}</Typography>;

Metric.Title = props => (
  <Typography variant="h6" color="textSecondary">
    {props.children}
  </Typography>
);

export const MarketCap = () => {
  const marketCap = useSelector(state => state.app.marketCap);

  return (
    <Metric className="market center">
      <Metric.Title>Market Cap</Metric.Title>
      <Metric.Value>{marketCap && formatCurrency(marketCap, 0)}</Metric.Value>
    </Metric>
  );
};

export const REAPrice = () => {
  const marketPrice = useSelector(state => state.app.marketPrice);

  return (
    <Metric className="price center">
      <Metric.Title>REA Price</Metric.Title>
      <Metric.Value>{marketPrice && formatCurrency(marketPrice, 2)}</Metric.Value>
    </Metric>
  );
};

export const CircSupply = () => {
  const circSupply = useAppSelector(state => {
    return state.app.circSupply || 0;
  });
  const totalSupply = useAppSelector(state => {
    console.log("state", state);
    return state.app.totalSupply || 0;
  });
  console.log("one", totalSupply);
  const isDataLoaded = circSupply && totalSupply;
  console.log("isDataLoaded", isDataLoaded);
  return (
    <Metric className="circ center">
      <Metric.Title>Circulating Supply (total)</Metric.Title>
      <Metric.Value>{isDataLoaded && parseInt(circSupply) + " / " + parseInt(totalSupply)}</Metric.Value>
    </Metric>
  );
};

export const BackingPerREA = () => {
  const backingPerRea = useSelector(state => state.app.treasuryMarketValue / state.app.circSupply);
  console.log("backingPerRea: ", backingPerRea);

  return (
    <Metric className="bpo center">
      <Metric.Title>Backing per REA</Metric.Title>
      <Metric.Value>{!isNaN(backingPerRea) && formatCurrency(backingPerRea, 2)}</Metric.Value>
    </Metric>
  );
};

export const CurrentIndex = () => {
  const currentIndex = useSelector(state => state.app.currentIndex);

  return (
    <Metric className="index center">
      <Metric.Title>
        Current Index
        <InfoTooltip message="The current index tracks the amount of sREA accumulated since the beginning of staking. Basically, how much sREA one would have if they staked and held a single REA from day 1." />
      </Metric.Title>
      <Metric.Value>{currentIndex && trim(currentIndex, 2) + " sREA"}</Metric.Value>
    </Metric>
  );
};

export const WSREAPrice = () => {
  const wsReaPrice = useSelector(state => state.app.marketPrice * state.app.currentIndex);

  return (
    <Metric className="wsoprice center">
      <Metric.Title>
        wsREA Price
        <InfoTooltip
          message={
            "wsREA = sREA * index\n\nThe price of wsREA is equal to the price of REA multiplied by the current index"
          }
        />
      </Metric.Title>
      <Metric.Value>{wsReaPrice && formatCurrency(wsReaPrice, 2)}</Metric.Value>
    </Metric>
  );
};

export const TVL = () => {
  return (
    <Metric className="wsoprice center">
      <Metric.Title>TVL</Metric.Title>
      <Metric.Value>1000</Metric.Value>
    </Metric>
  );
};

export const Stakingreturn = () => {
  return (
    <Metric className="wsoprice center">
      <Metric.Title>Staking Return Since Start</Metric.Title>
      <Metric.Value>4.8%</Metric.Value>
    </Metric>
  );
};

export const Time = () => {
  return (
    <Metric className="wsoprice center">
      <Metric.Title>Let the Game Begin</Metric.Title>
      <Metric.Value>18d 9h 0m 18s</Metric.Value>
    </Metric>
  );
};

export const Apy = () => {
  return (
    <Metric className="wsoprice center">
      <Metric.Title>APY</Metric.Title>
      <Metric.Value>{apy}</Metric.Value>
    </Metric>
  );
};
