import { useState, useEffect } from "react";
import { addresses, TOKEN_DECIMALS } from "../../constants";
import { NavLink } from "react-router-dom";
import {
  Link,
  SvgIcon,
  Popper,
  Button,
  Paper,
  Typography,
  Divider,
  Box,
  Fade,
  Slide,
  Snackbar,
} from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-fill.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";
import { ReactComponent as sReaTokenImg } from "../../assets/tokens/token_sREA.svg";
import { ReactComponent as wsReaTokenImg } from "../../assets/tokens/token_wsREA.svg";
import { ReactComponent as reaTokenImg } from "../../assets/tokens/token_REA.svg";
import { ReactComponent as t33TokenImg } from "../../assets/tokens/token_33T.svg";

import "./reamenu.scss";
import { dai, frax } from "src/helpers/AllBonds";
import { Trans } from "@lingui/macro";
import { useWeb3Context } from "../../hooks/web3Context";

import ReaImg from "src/assets/tokens/REA.png";
import SReaImg from "src/assets/tokens/sREA.png";
import WsReaImg from "src/assets/tokens/token_wsREA.svg";
import token33tImg from "src/assets/tokens/token_33T.svg";

import { segmentUA } from "../../helpers/userAnalyticHelpers";

const addTokenToWallet = (tokenSymbol, tokenAddress, address) => async () => {
  if (window.ethereum) {
    const host = window.location.origin;
    let tokenPath;
    let tokenDecimals = TOKEN_DECIMALS;
    switch (tokenSymbol) {
      case "REA":
        tokenPath = ReaImg;
        break;
      case "33T":
        tokenPath = token33tImg;
        break;
      case "wsREA":
        tokenPath = WsReaImg;
        tokenDecimals = 18;
        break;
      default:
        tokenPath = SReaImg;
    }
    const imageURL = `${host}/${tokenPath}`;

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: imageURL,
          },
        },
      });
      let uaData = {
        address: address,
        type: "Add Token",
        tokenName: tokenSymbol,
      };
      segmentUA(uaData);
    } catch (error) {
      console.log(error);
    }
  }
};

function ReaMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [chainIdChecked, setChainIdChecked] = useState(false);
  const isEthereumAPIAvailable = window.ethereum;
  const { chainID, address, connected, changeNetwork, isMetaMask, connectedChainID } = useWeb3Context();
  const networkID = chainID;

  const SREA_ADDRESS = addresses[networkID].SREA_ADDRESS;
  const REA_ADDRESS = addresses[networkID].REA_ADDRESS;
  const PT_TOKEN_ADDRESS = addresses[networkID].PT_TOKEN_ADDRESS;
  const WSREA_ADDRESS = addresses[networkID].WSREA_ADDRESS;
  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = "rea-popper";
  const daiAddress = dai.getAddressForReserve(networkID);
  const fraxAddress = frax.getAddressForReserve(networkID);
  const action = (
    <Button variant="contained" onClick={changeNetwork} color="primary" size="small">
      Switch to Aurora Network
    </Button>
  );

  useEffect(() => {
    //console.log("in reaMenu: ", provider.getNetwork());
    console.log(connectedChainID);
    if (connected && connectedChainID !== 1313161555 && connectedChainID !== 1313161554) {
      console.log("wrong chain id");
      setChainIdChecked(false);
    } else {
      setChainIdChecked(true);
    }
  }, [connected, connectedChainID]);

  return (
    <>
      <Snackbar
        open={!chainIdChecked}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // eslint-disable-next-line prettier/prettier
        message={
          'Wrong Network... \ Please switch to Aurora Network \ '}
        action={isMetaMask ? action : null}
      />
      <Box
        component="div"
        onMouseEnter={e => handleClick(e)}
        onMouseLeave={e => handleClick(e)}
        id="rea-menu-button-hover"
      >
        <Button
          id="rea-menu-button"
          size="large"
          variant="contained"
          color="secondary"
          title="REA"
          aria-describedby={id}
        >
          <SvgIcon component={InfoIcon} color="primary" />
          <Typography>REA</Typography>
        </Button>
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" transition>
          {({ TransitionProps }) => {
            return (
              <Fade {...TransitionProps} timeout={100}>
                <Paper className="rea-menu" elevation={1}>
                  <Box component="div" className="buy-tokens">
                    {/* <Link
                      href={`https://app.sushi.com/swap?inputCurrency=${daiAddress}&outputCurrency=${REA_ADDRESS}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button size="large" variant="contained" color="secondary" fullWidth>
                        <Typography align="left">
                          <Trans>Buy on {new String("Sushiswap")}</Trans>
                          <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                        </Typography>
                      </Button>
                    </Link> */}

                    {/* <Link
                      href={`https://app.uniswap.org/#/swap?inputCurrency=${fraxAddress}&outputCurrency=${REA_ADDRESS}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button size="large" variant="contained" color="secondary" fullWidth>
                        <Typography align="left">
                          <Trans>Buy on {new String("Uniswap")}</Trans>
                          <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                        </Typography>
                      </Button>
                    </Link> */}
                    {/* 
                    <Link component={NavLink} to="/wrap" style={{ textDecoration: "none" }}>
                      <Button size="large" variant="contained" color="secondary" fullWidth>
                        <Typography align="left">Wrap sREA</Typography>
                      </Button>
                    </Link> */}
                  </Box>

                  {/* <Box component="div" className="data-links">
                    <Divider color="secondary" className="less-margin" />
                    <Link href={`https://dune.xyz/shadow/Borealis-(REA)`} target="_blank" rel="noreferrer">
                      <Button size="large" variant="contained" color="secondary" fullWidth>
                        <Typography align="left">
                          Shadow's Dune Dashboard <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                        </Typography>
                      </Button>
                    </Link>
                  </Box> */}

                  {isEthereumAPIAvailable ? (
                    <Box className="add-tokens">
                      <Divider color="secondary" />
                      <p>
                        <Trans>ADD TOKEN TO WALLET</Trans>
                      </p>
                      <Box display="flex" flexDirection="row" justifyContent="space-between">
                        {REA_ADDRESS && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={addTokenToWallet("REA", REA_ADDRESS, address)}
                          >
                            {/* <SvgIcon
                              component={reaTokenImg}
                              viewBox="0 0 32 32"
                              style={{ height: "25px", width: "25px" }}
                            /> */}
                            <img src={ReaImg} style={{ height: "25px", width: "25px" }} alt="logo" />
                            <Typography variant="body1">REA</Typography>
                          </Button>
                        )}
                        {SREA_ADDRESS && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={addTokenToWallet("sREA", SREA_ADDRESS, address)}
                          >
                            {/* <SvgIcon
                              component={sReaTokenImg}
                              viewBox="0 0 100 100"
                              style={{ height: "25px", width: "25px" }}
                            /> */}
                            <img src={SReaImg} style={{ height: "25px", width: "25px" }} alt="logo" />
                            <Typography variant="body1">sREA</Typography>
                          </Button>
                        )}
                        {/* {WSREA_ADDRESS && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={addTokenToWallet("wsREA", WSREA_ADDRESS, address)}
                          >
                            <SvgIcon
                              component={wsReaTokenImg}
                              viewBox="0 0 180 180"
                              style={{ height: "25px", width: "25px" }}
                            />
                            <Typography variant="body1">wsREA</Typography>
                          </Button>
                        )}
                        {PT_TOKEN_ADDRESS && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={addTokenToWallet("33T", PT_TOKEN_ADDRESS, address)}
                          >
                            <SvgIcon
                              component={t33TokenImg}
                              viewBox="0 0 1000 1000"
                              style={{ height: "25px", width: "25px" }}
                            />
                            <Typography variant="body1">33T</Typography>
                          </Button>
                        )} */}
                      </Box>
                    </Box>
                  ) : null}

                  <Divider color="secondary" />
                  {/* <Link
                    href="https://docs.borealisdao.finance/using-the-website/unstaking_lp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button size="large" variant="contained" color="secondary" fullWidth>
                      <Typography align="left">
                        <Trans>Unstake Legacy LP Token</Trans>
                      </Typography>
                    </Button>
                  </Link> */}
                </Paper>
              </Fade>
            );
          }}
        </Popper>
      </Box>
    </>
  );
}

export default ReaMenu;
