import { SvgIcon, Link } from "@material-ui/core";
import { ReactComponent as Reddit } from "../../assets/icons/reddit.svg";
import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Discord } from "../../assets/icons/discord.svg";
import { ReactComponent as Telegram } from "../../assets/icons/telegram.svg";

export default function Social() {
  return (
    <div className="social-row">
      <a href="https://twitter.com/REA_wtf" target="_blank">
        <SvgIcon viewBox="0 0 24 24" component={Twitter} />
      </a>
      <a href="https://t.me/REA_WTF" target="_blank">
        <SvgIcon viewBox="0 0 24 24" component={Telegram} />
      </a>
      <a href="https://discord.gg/NPRprZeEJh" target="_blank">
        <SvgIcon viewBox="0 0 24 24" component={Discord} />
      </a>
      <a href="https://rea-wtf.medium.com/" target="_blank">
        <SvgIcon viewBox="0 0 24 24" component={Medium} />
      </a>
      <a href="https://www.reddit.com/r/REA_WTF/" target="_blank">
        <SvgIcon viewBox="0 0 24 24" component={Reddit} />
      </a>
    </div>
  );
}
