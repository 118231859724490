export const THE_GRAPH_URL = "";
export const EPOCH_INTERVAL = 2200;

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 1;

export const TOKEN_DECIMALS = 9;

interface IPoolGraphURLS {
  [index: string]: string;
}

export const POOL_GRAPH_URLS: IPoolGraphURLS = {
  4: "https://api.thegraph.com/subgraphs/name/pooltogether/rinkeby-v3_4_3",
  1: "https://api.thegraph.com/subgraphs/name/pooltogether/pooltogether-v3_4_3",
};

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  // 4: {
  //   DAI_ADDRESS: "0xB2180448f8945C8Cc8AE9809E67D6bd27d8B2f2C", // duplicate
  //   REA_ADDRESS: "0xC0b491daBf3709Ee5Eb79E603D73289Ca6060932",
  //   STAKING_ADDRESS: "0xC5d3318C0d74a72cD7C55bdf844e24516796BaB2",
  //   STAKING_HELPER_ADDRESS: "0xf73f23Bb0edCf4719b12ccEa8638355BF33604A1",
  //   OLD_STAKING_ADDRESS: "0xb640AA9082ad720c60102489b806E665d67DCE32",
  //   SREA_ADDRESS: "0x1Fecda1dE7b6951B248C0B62CaeBD5BAbedc2084",
  //   WSREA_ADDRESS: "0xe73384f11Bb748Aa0Bc20f7b02958DF573e6E2ad",
  //   OLD_SREA_ADDRESS: "0x8Fc4167B0bdA22cb9890af2dB6cB1B818D6068AE",
  //   MIGRATE_ADDRESS: "0x3BA7C6346b93DA485e97ba55aec28E8eDd3e33E2",
  //   DISTRIBUTOR_ADDRESS: "0x0626D5aD2a230E05Fb94DF035Abbd97F2f839C3a",
  //   BONDINGCALC_ADDRESS: "0xaDBE4FA3c2fcf36412D618AfCfC519C869400CEB",
  //   CIRCULATING_SUPPLY_ADDRESS: "0x5b0AA7903FD2EaA16F1462879B71c3cE2cFfE868",
  //   TREASURY_ADDRESS: "0x0d722D813601E48b7DAcb2DF9bae282cFd98c6E7",
  //   REDEEM_HELPER_ADDRESS: "0xBd35d8b2FDc2b720842DB372f5E419d39B24781f",
  //   PT_TOKEN_ADDRESS: "0x0a2d026bacc573a8b5a2b049f956bdf8e5256cfd", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS
  //   PT_PRIZE_POOL_ADDRESS: "0xf9081132864ed5e4980CFae83bDB122d86619281", // NEW
  //   PT_PRIZE_STRATEGY_ADDRESS: "0x2Df17EA8D6B68Ec444c9a698315AfB36425dac8b", // NEW
  // },
  // 4: {
  //   DAI_ADDRESS: "0x073A5e8409b9DEe4dA7a2ec372117c698c8680ad", // updated
  //   REA_ADDRESS: "0xE280155f601da8Ee2FF51E1E97812ee48A2c2D50", //updated
  //   STAKING_ADDRESS: "0xc7B26a4F0E6288155380BB08B912cA842C5f7489", //updated
  //   STAKING_HELPER_ADDRESS: "0x7b7aE75829aea68299976337E084475252431b81", //updated
  //   OLD_STAKING_ADDRESS: "0xc7B26a4F0E6288155380BB08B912cA842C5f7489", //updated
  //   SREA_ADDRESS: "0x40D5B5816b6ddC80d4EBac88380fCf3f234Eed25", //updated
  //   WSREA_ADDRESS: "0xe73384f11Bb748Aa0Bc20f7b02958DF573e6E2ad",
  //   OLD_SREA_ADDRESS: "0x40D5B5816b6ddC80d4EBac88380fCf3f234Eed25", //updated
  //   MIGRATE_ADDRESS: "0x3BA7C6346b93DA485e97ba55aec28E8eDd3e33E2",
  //   DISTRIBUTOR_ADDRESS: "0xA056d7A8F4f57d8F24E0565c19eeE62bEF20565d",
  //   BONDINGCALC_ADDRESS: "0x0af405B9863A8f1d8c7CC05fd8B95240b6cCE360",
  //   CIRCULATING_SUPPLY_ADDRESS: "0x5b0AA7903FD2EaA16F1462879B71c3cE2cFfE868",
  //   TREASURY_ADDRESS: "0x16621eA258FbC11e3b00552d4522C1cC3CC2C63f",
  //   REDEEM_HELPER_ADDRESS: "0xBd35d8b2FDc2b720842DB372f5E419d39B24781f",
  //   PT_TOKEN_ADDRESS: "0x0a2d026bacc573a8b5a2b049f956bdf8e5256cfd", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS
  //   PT_PRIZE_POOL_ADDRESS: "0xf9081132864ed5e4980CFae83bDB122d86619281", // NEW
  //   PT_PRIZE_STRATEGY_ADDRESS: "0x2Df17EA8D6B68Ec444c9a698315AfB36425dac8b", // NEW
  // },
  1313161555: {
    DAI_ADDRESS: "0xdDEf05fCC44DD11511933b8B4c3a512030c569cf", // updated
    // REA_ADDRESS: "0x87ed7d26821cf4D26c0a9857825042235f62d277", //updatedy
    REA_ADDRESS: "0xA6326b1Dfea942F09665d1284cAfB0e802cf367a", //updated
    AURORA_BOND_ADDRESS: "0x1504Ac875C36b04BEAe5BF18B0A7a52e0Ddb60C1",

    STAKING_ADDRESS: "0x82CAabEE9652817faD6A78Af140271a7d96Ce273", //updated
    //STAKING_ADDRESS: "0x7049aDa9F23C3BA1B9ebb5cb0767688c195E3A21",
    STAKING_HELPER_ADDRESS: "0x9844eb37F9e2B90B259d2Ec4f4E0400096A86524", //updated
    OLD_STAKING_ADDRESS: "0xAbe47F2A6eB125c55645A896cb3fcaD077034582", //updated
    SREA_ADDRESS: "0xAE191744B99753beDb1B326fb622664a588244c3", //updated
    WSREA_ADDRESS: "0xe73384f11Bb748Aa0Bc20f7b02958DF573e6E2ad",
    OLD_SREA_ADDRESS: "0x0d05f4ed8deE67052ad6296cA2899713CA9a967e", //updated
    MIGRATE_ADDRESS: "0x3BA7C6346b93DA485e97ba55aec28E8eDd3e33E2",
    DISTRIBUTOR_ADDRESS: "0x8e619856EE8963295d21c446B3a9a515e970Da59",
    BONDINGCALC_ADDRESS: "0xfDfB40bfa1057b7f8E0017710bC78fb1e01E537B",
    CIRCULATING_SUPPLY_ADDRESS: "0x5b0AA7903FD2EaA16F1462879B71c3cE2cFfE868",
    TREASURY_ADDRESS: "0xeFF771887C08D7508Df05a33F199f4Ebc3d993FB",
    REDEEM_HELPER_ADDRESS: "0xBd35d8b2FDc2b720842DB372f5E419d39B24781f",
    PT_TOKEN_ADDRESS: "0x0a2d026bacc573a8b5a2b049f956bdf8e5256cfd", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS
    PT_PRIZE_POOL_ADDRESS: "0xf9081132864ed5e4980CFae83bDB122d86619281", // NEW
    PT_PRIZE_STRATEGY_ADDRESS: "0x2Df17EA8D6B68Ec444c9a698315AfB36425dac8b", // NEW
  },

  // 1313161554: {
  //   DAI_ADDRESS: "0xe3520349F477A5F6EB06107066048508498A291b", // updated
  //   REA_ADDRESS: "0x249366dec231d8d2233961F3D2E12ddbD42C4e0B", //updatedy
  //   STAKING_ADDRESS: "0x1D4f973820e00523B0eB8B651FD04683bF419F38", //updated
  //   STAKING_HELPER_ADDRESS: "0x57Dd63b4e885a7C241Bf799F15f84c09B913E49e", //updated
  //   OLD_STAKING_ADDRESS: "0x1D4f973820e00523B0eB8B651FD04683bF419F38", //updated
  //   SREA_ADDRESS: "0x68514B5207f8d871955d7556C3477945cAb1E345", //updated
  //   WSREA_ADDRESS: "0x0CCdd21e9235Ab977bAF1bf325741b0fFB931F94",
  //   OLD_SREA_ADDRESS: "0x6b0800daA4245Fc49EDe833BF9881b31eCE34759", //updated
  //   MIGRATE_ADDRESS: "0x3BA7C6346b93DA485e97ba55aec28E8eDd3e33E2",
  //   DISTRIBUTOR_ADDRESS: "0x4869051a85e64C49a814d7467414aB5B8e943F18",
  //   BONDINGCALC_ADDRESS: "0x0af405B9863A8f1d8c7CC05fd8B95240b6cCE360",
  //   CIRCULATING_SUPPLY_ADDRESS: "0x5b0AA7903FD2EaA16F1462879B71c3cE2cFfE868",
  //   TREASURY_ADDRESS: "0x72f7c1D04874084252a621BC6b47E27E5cF9763e",
  //   REDEEM_HELPER_ADDRESS: "0xBd35d8b2FDc2b720842DB372f5E419d39B24781f",
  //   PT_TOKEN_ADDRESS: "0x0a2d026bacc573a8b5a2b049f956bdf8e5256cfd", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS
  //   PT_PRIZE_POOL_ADDRESS: "0xf9081132864ed5e4980CFae83bDB122d86619281", // NEW
  //   PT_PRIZE_STRATEGY_ADDRESS: "0x2Df17EA8D6B68Ec444c9a698315AfB36425dac8b", // NEW
  // },
  1313161554: {
    DAI_ADDRESS: "0x89D71b4D27F0808a37707FA11558825cC19535f5", // updated
    REA_ADDRESS: "0x60E3fe317bdE3c010aaa85b31452Bc7817bbC781", //updatedy
    STAKING_ADDRESS: "0x4B44F9b48bC4C69Ea885F06A2C226645114936e8", //updated
    STAKING_HELPER_ADDRESS: "0xCa50726dCF8029683ae66996fFA75CA144DE8839", //updated
    OLD_STAKING_ADDRESS: "0x4B44F9b48bC4C69Ea885F06A2C226645114936e8", //updated
    SREA_ADDRESS: "0x455D154F9E451A819eF421f8E6e1038dA469E428", //updated
    WSREA_ADDRESS: "0x0CCdd21e9235Ab977bAF1bf325741b0fFB931F94",
    OLD_SREA_ADDRESS: "0x6b0800daA4245Fc49EDe833BF9881b31eCE34759", //updated
    MIGRATE_ADDRESS: "0x3BA7C6346b93DA485e97ba55aec28E8eDd3e33E2",
    DISTRIBUTOR_ADDRESS: "0xE8794FcE19FBaa3fd14e26ae901ABA83f92A4553",
    BONDINGCALC_ADDRESS: "0x0af405B9863A8f1d8c7CC05fd8B95240b6cCE360",
    CIRCULATING_SUPPLY_ADDRESS: "0x5b0AA7903FD2EaA16F1462879B71c3cE2cFfE868",
    TREASURY_ADDRESS: "0xF41A577a09B1E70c42C672440920fCd670F5e262",
    REDEEM_HELPER_ADDRESS: "0xBd35d8b2FDc2b720842DB372f5E419d39B24781f",
    PT_TOKEN_ADDRESS: "0x0a2d026bacc573a8b5a2b049f956bdf8e5256cfd", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS
    PT_PRIZE_POOL_ADDRESS: "0xf9081132864ed5e4980CFae83bDB122d86619281", // NEW
    PT_PRIZE_STRATEGY_ADDRESS: "0x2Df17EA8D6B68Ec444c9a698315AfB36425dac8b", // NEW
  },
  // 1: {
  //   DAI_ADDRESS: "0x6b175474e89094c44da98b954eedeac495271d0f", // duplicate
  //   REA_ADDRESS: "0x383518188c0c6d7730d91b2c03a03c837814a899",
  //   STAKING_ADDRESS: "0xfd31c7d00ca47653c6ce64af53c1571f9c36566a", // The new staking contract
  //   STAKING_HELPER_ADDRESS: "0xc8c436271f9a6f10a5b80c8b8ed7d0e8f37a612d", // Helper contract used for Staking only
  //   OLD_STAKING_ADDRESS: "0x0822F3C03dcc24d200AFF33493Dc08d0e1f274A2",
  //   SREA_ADDRESS: "0x04F2694C8fcee23e8Fd0dfEA1d4f5Bb8c352111F",
  //   WSREA_ADDRESS: "0xca76543cf381ebbb277be79574059e32108e3e65",
  //   OLD_SREA_ADDRESS: "0x31932E6e45012476ba3A3A4953cbA62AeE77Fbbe",
  //   PRESALE_ADDRESS: "0xcBb60264fe0AC96B0EFa0145A9709A825afa17D8",
  //   AREA_ADDRESS: "0x24ecfd535675f36ba1ab9c5d39b50dc097b0792e",
  //   MIGRATE_ADDRESS: "0xC7f56EC779cB9e60afA116d73F3708761197dB3d",
  //   DISTRIBUTOR_ADDRESS: "0xbe731507810C8747C3E01E62c676b1cA6F93242f",
  //   BONDINGCALC_ADDRESS: "0xcaaa6a2d4b26067a391e7b7d65c16bb2d5fa571a",
  //   CIRCULATING_SUPPLY_ADDRESS: "0x0efff9199aa1ac3c3e34e957567c1be8bf295034",
  //   TREASURY_ADDRESS: "0x31f8cc382c9898b273eff4e0b7626a6987c846e8",
  //   CRUCIBLE_REA_LUSD: "0x2230ad29920D61A535759678191094b74271f373",
  //   LQTY: "0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d",
  //   MIST: "0x88acdd2a6425c3faae4bc9650fd7e27e0bebb7ab",
  //   REDEEM_HELPER_ADDRESS: "0xE1e83825613DE12E8F0502Da939523558f0B819E",
  //   FUSE_6_SREA: "0x59bd6774c22486d9f4fab2d448dce4f892a9ae25", // Tetranode's Locker
  //   FUSE_18_SREA: "0x6eDa4b59BaC787933A4A21b65672539ceF6ec97b", // Borealis Pool Party
  //   FUSE_36_SREA: "0x252d447c54F33e033AD04048baEAdE7628cB1274", // Fraximalist Money Market
  //   PT_TOKEN_ADDRESS: "0x0E930b8610229D74Da0A174626138Deb732cE6e9", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS
  //   PT_PRIZE_POOL_ADDRESS: "0xEaB695A8F5a44f583003A8bC97d677880D528248", // NEW
  //   PT_PRIZE_STRATEGY_ADDRESS: "0xf3d253257167c935f8C62A02AEaeBB24c9c5012a", // NEW
  // },
};
