import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20ABI } from "../abi/IERC20.json";
import { abi as BorealisStakingABI } from "../abi/BorealisStakingv2.json";
import { abi as StakingHelperABI } from "../abi/StakingHelper.json";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances } from "./AccountSlice";
import { error, info } from "../slices/MessagesSlice";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError } from "./interfaces";
import { segmentUA } from "../helpers/userAnalyticHelpers";
import { IERC20, BorealisStakingv2, StakingHelper } from "src/typechain";

interface IUAData {
  address: string;
  value: string;
  approved: boolean;
  txHash: string | null;
  type: string | null;
}

function alreadyApprovedToken(token: string, stakeAllowance: BigNumber, unstakeAllowance: BigNumber) {
  // set defaults
  let bigZero = BigNumber.from("0");
  let applicableAllowance = bigZero;

  // determine which allowance to check
  if (token === "rea") {
    applicableAllowance = stakeAllowance;
  } else if (token === "srea") {
    applicableAllowance = unstakeAllowance;
  }

  // check if allowance exists
  if (applicableAllowance.gt(bigZero)) return true;

  return false;
}

export const changeApproval = createAsyncThunk(
  "stake/changeApproval",
  async ({ token, provider, address, networkID }: IChangeApprovalAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    console.log("signer", signer);
    const reaContract = new ethers.Contract(addresses[networkID].REA_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const sreaContract = new ethers.Contract(addresses[networkID].SREA_ADDRESS as string, ierc20ABI, signer) as IERC20;
    let approveTx;
    let stakeAllowance = await reaContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
    console.log("helperaddress", addresses[networkID].STAKING_HELPER_ADDRESS);
    let unstakeAllowance = await sreaContract.allowance(address, addresses[networkID].STAKING_ADDRESS);

    // return early if approval has already happened
    if (alreadyApprovedToken(token, stakeAllowance, unstakeAllowance)) {
      dispatch(info("Approval completed."));
      return dispatch(
        fetchAccountSuccess({
          staking: {
            reaStake: +stakeAllowance,
            reaUnstake: +unstakeAllowance,
          },
        }),
      );
    }

    try {
      if (token === "rea") {
        // won't run if stakeAllowance > 0
        approveTx = await reaContract.approve(
          addresses[networkID].STAKING_HELPER_ADDRESS,
          ethers.utils.parseUnits("1000000000", 9).toString(),
        );
      } else if (token === "srea") {
        approveTx = await sreaContract.approve(
          addresses[networkID].STAKING_ADDRESS,
          ethers.utils.parseUnits("1000000000", 9).toString(),
        );
      }

      const text = "Approve " + (token === "rea" ? "Staking" : "Unstaking");
      const pendingTxnType = token === "rea" ? "approve_staking" : "approve_unstaking";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));

        await approveTx.wait();
      }
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }

    // go get fresh allowances
    stakeAllowance = await reaContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
    unstakeAllowance = await sreaContract.allowance(address, addresses[networkID].STAKING_ADDRESS);
    console.warn("allowance", stakeAllowance);
    return dispatch(
      fetchAccountSuccess({
        staking: {
          reaStake: +stakeAllowance,
          reaUnstake: +unstakeAllowance,
        },
      }),
    );
  },
);

export const changeStake = createAsyncThunk(
  "stake/changeStake",
  async ({ action, value, provider, address, networkID }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const staking = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      BorealisStakingABI,
      signer,
    ) as BorealisStakingv2;
    const stakingHelper = new ethers.Contract(
      addresses[networkID].STAKING_HELPER_ADDRESS as string,
      StakingHelperABI,
      signer,
    ) as StakingHelper;

    let stakeTx;
    let uaData: IUAData = {
      address: address,
      value: value,
      approved: true,
      txHash: null,
      type: null,
    };
    try {
      if (action === "stake") {
        uaData.type = "stake";
        // const reaContract = new ethers.Contract(
        //   addresses[networkID].REA_ADDRESS as string,
        //   ierc20ABI,
        //   signer,
        // ) as IERC20;
        // let approveTx = await reaContract.approve(
        //   addresses[networkID].STAKING_HELPER_ADDRESS,
        //   ethers.utils.parseUnits("1000", 9).toString(),
        // );
        // const text = "Approve Staking";
        // const pendingTxnType = "approve_staking";
        // if (approveTx) {
        //   dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        //   await approveTx.wait();
        // }
        stakeTx = await stakingHelper.stake(ethers.utils.parseUnits(value, 9));
      } else {
        uaData.type = "unstake";
        stakeTx = await staking.unstake(ethers.utils.parseUnits(value, 9), true);
      }
      const pendingTxnType = action === "stake" ? "staking" : "unstaking";
      uaData.txHash = stakeTx.hash;
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(action), type: pendingTxnType }));
      await stakeTx.wait();
    } catch (e: unknown) {
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        dispatch(error(rpcError.message));
      }
      return;
    } finally {
      if (stakeTx) {
        segmentUA(uaData);
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
    dispatch(getBalances({ address, networkID, provider }));
  },
);
