import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import Social from "./Social";
// import externalUrls from "./externalUrls";
import rea_dark from "../../assets/images/REA_dark.png";
import rea_light from "../../assets/images/REA_light.png";
import { ReactComponent as StakeIcon } from "../../assets/icons/stake.svg";
import { ReactComponent as BondIcon } from "../../assets/icons/bond.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import BorealisIcon from "../../assets/icons/borealis-nav-header.svg";
import Rea_Logo from "../../assets/images/Rea_With_Text.png";
import { ReactComponent as PoolTogetherIcon } from "../../assets/icons/33-together.svg";
import { ReactComponent as BridgeIcon } from "../../assets/icons/bridge.svg";
import { Trans } from "@lingui/macro";
import { trim, shorten } from "../../helpers";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import useBonds from "../../hooks/Bonds";
import { Paper, Link, Box, Typography, SvgIcon } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import "./sidebar.scss";
import { Swap } from "../../views/index";

const bridges = [
  { name: "Bridge from Ethereum", link: "https://rainbowbridge.app/transfer" },
  { name: "Bridge from Near", link: "https://rainbowbridge.app/transfer" },
  { name: "Bridge from Terra", link: "https://app.allbridge.io/bridge" },
  { name: "Bridge from BSC", link: "https://app.multichain.org/#/router" },
  { name: "Bridge from Avalanche", link: "https://app.multichain.org/#/router" },
  {
    name: "Bridge from Polygon",
    link: "https://app.multichain.org/#/router",
  },
  {
    name: "Synapse Bridge",
    link: "https://synapseprotocol.com/?inputCurrency=USDC&outputCurrency=USDC&outputChain=1313161554",
  },
];

function NavContent({ theme }) {
  const [isActive] = useState();
  const address = useAddress();
  const { chainID } = useWeb3Context();
  const { bonds } = useBonds(chainID);

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");
    if (currentPath.indexOf("dashboard") >= 0 && page === "dashboard") {
      return true;
    }
    if (currentPath.indexOf("stake") >= 0 && page === "stake") {
      return true;
    }
    if ((currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) && page === "bonds") {
      return true;
    }
    if (currentPath.indexOf("33-together") >= 0 && page === "33-together") {
      return true;
    }
    return false;
  }, []);

  return (
    <Paper className="dapp-sidebar">
      <Box className="dapp-sidebar-inner" display="flex" justifyContent="space-between" flexDirection="column">
        <div className="dapp-menu-top">
          <Box className="branding-header">
            <a href="https://rea.wtf/" target="_blank">
              <div style={{ marginBottom: 12 }}>
                <img src={Rea_Logo} alt="logo" width={100} />
              </div>
              {/* {theme === "dark" ? <img src={rea_dark} alt="logo" /> : <img src={rea_light} alt="logo" />} */}
            </a>

            {/* {address && (
              <div className="wallet-link">
                <Link href={`https://etherscan.io/address/${address}`} target="_blank">
                  {shorten(address)}
                </Link>
              </div>
            )} */}
          </Box>

          <div className="dapp-menu-links">
            <div className="dapp-nav" id="navbarNav">
              <Link
                component={NavLink}
                id="dash-nav"
                to="/dashboard"
                isActive={(match, location) => {
                  return checkPage(match, location, "dashboard");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography variant="h6">
                  <SvgIcon viewBox="0 0 24 24" component={DashboardIcon} />
                  <Trans>Dashboard</Trans>
                </Typography>
              </Link>

              {/* <Link
                component={NavLink}
                id="stake-nav"
                to="/"
                isActive={(match, location) => {
                  return checkPage(match, location, "stake");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography variant="h6">
                  <SvgIcon viewBox="0 0 24 24" component={StakeIcon} />
                  <Trans>Stake</Trans>
                </Typography>
              </Link> */}

              {/* <Link
                component={NavLink}
                id="33-together-nav"
                to="/33-together"
                isActive={(match, location) => {
                  return checkPage(match, location, "33-together");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography variant="h6">
                  <SvgIcon color="primary" component={PoolTogetherIcon} />
                  3,3 Together
                </Typography>
              </Link> */}

              <Link
                component={NavLink}
                id="bond-nav"
                to="/bonds"
                isActive={(match, location) => {
                  return checkPage(match, location, "bonds");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography variant="h6">
                  <SvgIcon viewBox="0 0 24 24" component={BondIcon} />
                  <Trans>Bond</Trans>
                </Typography>
              </Link>

              <div className="dapp-menu-data discounts">
                <div className="bond-discounts">
                  <Typography variant="body2">
                    <Trans>Bond discounts</Trans>
                  </Typography>
                  {bonds.map((bond, i) => (
                    <Link component={NavLink} to={`/bonds/${bond.name}`} key={i} className={"bond"}>
                      {!bond.bondDiscount ? (
                        <Skeleton variant="text" width={"150px"} />
                      ) : (
                        <Typography variant="body2">
                          {bond.displayName}

                          <span className="bond-pair-roi">
                            {!bond.isAvailable[chainID]
                              ? "Sold Out"
                              : `${bond.bondDiscount && trim(bond.bondDiscount * 100, 2)}%`}
                          </span>
                        </Typography>
                      )}
                    </Link>
                  ))}
                </div>
              </div>
              {/* <Link
                component={NavLink}
                id="bond-nav"
                to="/swap"
                style={{ marginTop: "1rem" }}
                isActive={(match, location) => {
                  return checkPage(match, location, "bonds");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography variant="h6">
                  <SvgIcon viewBox="0 0 24 24" component={BondIcon} />
                  <Trans>Swap</Trans>
                </Typography>
              </Link> */}

              <p id="bond-nav">
                <Typography variant="h6">
                  <SvgIcon viewBox="0 0 24 24" component={BridgeIcon} />
                  <Trans>Bridge</Trans>
                </Typography>
              </p>

              <div className="dapp-menu-data discounts">
                <div className="bond-discounts">
                  {bridges.map((bridge, i) => (
                    <Link href={`${bridge.link}`} target="_blank" key={i} className={"bond"}>
                      <Typography variant="body2">{bridge.name}</Typography>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Box className="dapp-menu-bottom" display="flex" justifyContent="space-between" flexDirection="column">
          {/* <div className="dapp-menu-external-links">
            {Object.keys(externalUrls).map((link, i) => {
              return (
                <Link key={i} href={`${externalUrls[link].url}`} target="_blank">
                  <Typography variant="h6">{externalUrls[link].icon}</Typography>
                  <Typography variant="h6">{externalUrls[link].title}</Typography>
                </Link>
              );
            })}
          </div> */}
          <div className="dapp-menu-social">
            <Social />
          </div>
        </Box>
      </Box>
    </Paper>
  );
}

export default NavContent;
