import { memo, useState } from "react";
import "./treasury-dashboard.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { Paper, Grid, Box, Zoom, Container, useMediaQuery } from "@material-ui/core";
import {
  MarketCap,
  REAPrice,
  WSREAPrice,
  CircSupply,
  BackingPerREA,
  CurrentIndex,
  Stakingreturn,
  TVL,
  Time,
} from "./components/Metric/Metric";
// import { stakingapy } from "../../utils/methods";
import {
  TotalValueDepositedGraph,
  MarketValueGraph,
  RiskFreeValueGraph,
  ProtocolOwnedLiquidityGraph,
  REAStakedGraph,
  APYOverTimeGraph,
  RunwayAvailableGraph,
} from "./components/Graph/Graph";
import { useTheme } from "@material-ui/core/styles";
import { useAppSelector } from "src/hooks";
// import { stakingapy } from "src/utils/methods";
const TreasuryDashboard = memo(() => {
  // const [value, setValue] = useState(0);
  // stakingapy().then((res: any) => {
  //   setValue(res);
  //   console.log("res", res);
  // });
  const isSmallScreen = useMediaQuery("(max-width: 650px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 379px)");
  const theme = useTheme();

  const currentIndex = useAppSelector(state => {
    return state.app.currentIndex;
  });

  return (
    <div id="treasury-dashboard-view" className={`${isSmallScreen && "smaller"} ${isVerySmallScreen && "very-small"}`}>
      <Container
        style={{
          paddingLeft: isSmallScreen || isVerySmallScreen ? "0" : "3.3rem",
          paddingRight: isSmallScreen || isVerySmallScreen ? "0" : "3.3rem",
        }}
      >
        <Paper className="rea-card">
          <Box className="box_wrapper">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper className="button">Buy</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className="button">Bond</Paper>
              </Grid>
            </Grid>
          </Box>

          <Grid container justifyContent="space-around" spacing={3}>
            <Grid item xs={12}>
              <Box className="grid_item_box" style={{ background: theme.palette.primary.main }}>
                <Time />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="grid_item_box" style={{ background: theme.palette.primary.main }}>
                <MarketCap />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="grid_item_box" style={{ background: theme.palette.primary.main }}>
                <REAPrice />
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <Box className="grid_item_box" style={{ background: theme.palette.primary.main }}>
                <WSREAPrice />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <Box className="grid_item_box" style={{ background: theme.palette.primary.main }}>
                <CircSupply />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="grid_item_box" style={{ background: theme.palette.primary.main }}>
                <TVL />
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <Box className="grid_item_box" style={{ background: theme.palette.primary.main }}>
                <CurrentIndex />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="grid_item_box" style={{ background: theme.palette.primary.main }}>
                <BackingPerREA />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="grid_item_box" style={{ background: theme.palette.primary.main }}>
                <Stakingreturn />
              </Box>
            </Grid> */}
          </Grid>
        </Paper>

        {/* <Zoom in={true}>
          <Grid container spacing={2} className="data-grid">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="rea-card rea-chart-card">
                <TotalValueDepositedGraph />
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="rea-card rea-chart-card">
                <MarketValueGraph />
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="rea-card rea-chart-card">
                <RiskFreeValueGraph />
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="rea-card">
                <ProtocolOwnedLiquidityGraph />
              </Paper>
            </Grid>
            Temporarily removed until correct data is in the graph
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper className="rea-card">
                <Chart
                  type="bar"
                  data={data}
                  dataKey={["holders"]}
                  headerText="Holders"
                  stroke={[theme.palette.text.secondary]}
                  headerSubText={`${data.length > 0 && data[0].holders}`}
                  bulletpointColors={bulletpoints.holder}
                  itemNames={tooltipItems.holder}
                  itemType={undefined}
                  infoTooltipMessage={tooltipInfoMessages.holder}
                  expandedGraphStrokeColor={theme.palette.graphStrokeColor}
                  scale={undefined}
                  color={undefined}
                  stroke={undefined}
                  dataFormat={undefined}
                  isPOL={undefined}
                  isStaked={undefined}
                />
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="rea-card">
                <REAStakedGraph />
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="rea-card">
                <APYOverTimeGraph />
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="rea-card">
                <RunwayAvailableGraph />
              </Paper>
            </Grid>
          </Grid>
        </Zoom> */}
      </Container>
    </div>
  );
});

const queryClient = new QueryClient();

// Normally this would be done
// much higher up in our App.
export default () => (
  <QueryClientProvider client={queryClient}>
    <TreasuryDashboard />
  </QueryClientProvider>
);
