import React, { useState, ReactElement, useContext, useEffect, useMemo, useCallback } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { StaticJsonRpcProvider, JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { IFrameEthereumProvider } from "@ledgerhq/iframe-provider";
import { EnvHelper } from "../helpers/Environment";
import { NodeHelper } from "src/helpers/NodeHelper";
import { addresses } from "src/constants";
import stakingabi from "../abi/BorealisStakingv2.json";
import sreaabi from "../abi/sREA.json";

/**
 * kept as function to mimic `getMainnetURI()`
 * @returns string
 */
function getTestnetURI() {
  //console.log("testnet", EnvHelper.alchemyTestnetURI);
  return EnvHelper.getSelfHostedNode()[0];
}

/**
 * determine if in IFrame for Ledger Live
 */
function isIframe() {
  return window.location !== window.parent.location;
}

const ALL_URIs = NodeHelper.getNodesUris();

/**
 * "intelligently" loadbalances production API Keys
 * @returns string
 */
function getMainnetURI(): string {
  // Shuffles the URIs for "intelligent" loadbalancing
  const allURIs = ALL_URIs.sort(() => Math.random() - 0.5);

  // There is no lightweight way to test each URL. so just return a random one.
  // if (workingURI !== undefined || workingURI !== "") return workingURI as string;
  const randomIndex = Math.floor(Math.random() * allURIs.length);
  // console.log("uri", allURIs[randomIndex]);

  return allURIs[randomIndex];
}

/*
  Types
*/
type onChainProvider = {
  connect: () => Promise<Web3Provider | undefined>;
  disconnect: () => void;
  hasCachedProvider: () => boolean;
  changeNetwork: () => void;
  address: string;
  chainID: number;
  connected: boolean;
  provider: JsonRpcProvider;
  uri: string;
  web3Modal: Web3Modal;
  stakeAPY: number;
  connectedChainID: number;
  isMetaMask: boolean;
};

export type Web3ContextData = {
  onChainProvider: onChainProvider;
} | null;

const Web3Context = React.createContext<Web3ContextData>(null);

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context);
  if (!web3Context) {
    throw new Error(
      "useWeb3Context() can only be used inside of <Web3ContextProvider />, " + "please declare it at a higher level.",
    );
  }
  const { onChainProvider } = web3Context;
  return useMemo<onChainProvider>(() => {
    return { ...onChainProvider };
  }, [web3Context]);
};

export const useAddress = () => {
  const { address } = useWeb3Context();
  return address;
};

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [connected, setConnected] = useState(false);
  console.log(EnvHelper.getDefaultChainID());
  // NOTE (appleseed): if you are testing on rinkeby you need to set chainId === 4 as the default for non-connected wallet testing...
  // ... you also need to set getTestnetURI() as the default uri state below
  const [chainID, setChainID] = useState(EnvHelper.getDefaultChainID() ?? 1313161555);
  const [connectedChainID, setConnectedChainID] = useState(0);
  const [isMetaMask, setIsMetaMask] = useState(false);
  const [address, setAddress] = useState("");
  const [stakeAPY, setStakeAPY] = useState(0);

  const [uri, setUri] = useState(getTestnetURI());

  const [provider, setProvider] = useState<JsonRpcProvider>(new StaticJsonRpcProvider(uri));

  const [web3Modal, setWeb3Modal] = useState<Web3Modal>(
    new Web3Modal({
      // network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              1313161554: getMainnetURI(),
              1313161555: getTestnetURI(),
            },
          },
        },
      },
    }),
  );

  const hasCachedProvider = (): boolean => {
    if (!web3Modal) return false;
    if (!web3Modal.cachedProvider) return false;
    return true;
  };

  // NOTE (appleseed): none of these listeners are needed for Backend API Providers
  // ... so I changed these listeners so that they only apply to walletProviders, eliminating
  // ... polling to the backend providers for network changes
  const _initListeners = useCallback(
    rawProvider => {
      if (!rawProvider.on) {
        return;
      }
      rawProvider.on("accountsChanged", async (accounts: string[]) => {
        setTimeout(() => window.location.reload(), 1);
      });

      rawProvider.on("chainChanged", async (chain: number) => {
        _checkNetwork(chain);
        setTimeout(() => window.location.reload(), 1);
      });

      rawProvider.on("network", (_newNetwork: any, oldNetwork: any) => {
        if (!oldNetwork) return;
        window.location.reload();
      });
    },
    [provider],
  );

  /**
   * throws an error if networkID is not 1 (mainnet) or 4 (rinkeby)
   */
  const _checkNetwork = (otherChainID: number): boolean => {
    if (chainID !== otherChainID) {
      console.warn("You are switching networks");
      if (otherChainID === 1313161554 || otherChainID === 1313161555) {
        // getStakeAPY();
        setChainID(otherChainID);
        otherChainID === 1313161554 ? setUri(getMainnetURI()) : setUri(getTestnetURI());
        console.log("testneturi", getTestnetURI());
        return true;
      }
      return false;
    }
    return true;
  };

  const networks = {
    mainnet: {
      chainId: "0x4e454152", // A 0x-prefixed hexadecimal string
      chainName: "Aurora Mainnet",
      nativeCurrency: {
        name: "Ether",
        symbol: "aETH",
        decimals: 18,
      },
      rpcUrls: ["https://mainnet.aurora.dev"],
      blockExplorerUrls: ["https://aurorascan.dev/"],
    },
  };

  // Change Network for metamask
  const changeNetwork = async () => {
    try {
      console.log("in here", provider.connection.url);
      if (!window.ethereum && !connected) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0x4e454152", // "0x4e454152" for mainnet, "0x4e454153" for testnet
          },
        ],
      });
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [networks.mainnet],
          });
        } catch (addError) {
          // handle "add" error
          console.log("Error on adding network", addError);
        }
      }
      console.log("Error on switching Networks");
    }
  };

  useEffect(() => {
    if (connected && (chainID === 1313161554 || chainID === 1313161555)) getStakeAPY();
  }, [chainID, connected]);

  const getStakeAPY = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(chainID === 1313161554 ? getMainnetURI() : getTestnetURI());
      const stakingContract = new ethers.Contract(addresses[chainID].STAKING_ADDRESS, stakingabi.abi, provider);
      const srea = new ethers.Contract(addresses[chainID].SREA_ADDRESS, sreaabi.abi, provider);
      const epoch = await stakingContract.epoch();
      console.log("epoch: ", epoch);
      const stakingReward = epoch.distribute;
      const circ = await srea.circulatingSupply();

      const stakingRebase = Number(stakingReward.toString()) / Number(circ.toString());

      const fiveDayRate = (await Math.pow(1 + stakingRebase, 5 * 3)) - 1;
      const stakingAPY = (await Math.pow(1 + stakingRebase, 365 * 3)) - 1;
      console.log("methods.js", stakingAPY);
      setStakeAPY(stakingAPY);
    } catch (err) {
      console.log("Error fetching stakeAPY: ", err);
    }
  };

  getStakeAPY();

  // connect - only runs for WalletProviders
  const connect = useCallback(async () => {
    // handling Ledger Live;
    let rawProvider;
    if (isIframe()) {
      rawProvider = new IFrameEthereumProvider();
    } else {
      rawProvider = await web3Modal.connect();
    }

    // new _initListeners implementation matches Web3Modal Docs
    // ... see here: https://github.com/Web3Modal/web3modal/blob/2ff929d0e99df5edf6bb9e88cff338ba6d8a3991/example/src/App.tsx#L185
    _initListeners(rawProvider);
    const connectedProvider = new Web3Provider(rawProvider, "any");
    const networkID = (await connectedProvider.getNetwork()).chainId;
    const connectedAddress = await connectedProvider.getSigner().getAddress();
    const validNetwork = _checkNetwork(networkID);
    if (validNetwork) {
      console.error("Wrong network, please switch to  Aurora mainnet");
      setProvider(connectedProvider);
    }
    setIsMetaMask(connectedProvider.connection.url === "metamask");
    console.log("is metamask ", isMetaMask);
    // Save everything after we've validated the right network.
    // Eventually we'll be fine without doing network validations.
    setAddress(connectedAddress);
    console.log("connecting to chain id: ", networkID);
    setConnectedChainID(networkID);
    // Keep this at the bottom of the method, to ensure any repaints have the data we need
    setConnected(true);

    return connectedProvider;
  }, [provider, web3Modal, connected]);

  const disconnect = useCallback(async () => {
    console.log("disconnecting");
    web3Modal.clearCachedProvider();
    setConnected(false);

    setTimeout(() => {
      window.location.reload();
    }, 1);
  }, [provider, web3Modal, connected]);

  const onChainProvider = useMemo<onChainProvider>(
    () => ({
      connect,
      disconnect,
      hasCachedProvider,
      changeNetwork,
      provider,
      connected,
      address,
      chainID,
      web3Modal,
      uri,
      stakeAPY,
      connectedChainID,
      isMetaMask,
    }),
    [
      connect,
      disconnect,
      hasCachedProvider,
      provider,
      connected,
      address,
      chainID,
      web3Modal,
      uri,
      stakeAPY,
      connectedChainID,
      isMetaMask,
    ],
  );

  useEffect(() => {
    // logs non-functioning nodes && returns an array of working mainnet nodes
    NodeHelper.checkAllNodesStatus().then((validNodes: any) => {
      validNodes = validNodes.filter((url: boolean | string) => url !== false);
      if (!validNodes.includes(uri) && NodeHelper.retryOnInvalid()) {
        // force new provider...
        setTimeout(() => {
          window.location.reload();
        }, 1);
      }
    });
  }, []);

  return <Web3Context.Provider value={{ onChainProvider }}>{children}</Web3Context.Provider>;
};
